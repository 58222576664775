
import template from './edit-ace.html';

/**
 * View Model
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;

		this.resource = dialog.resource;
		this.ace = dialog.ace;

		this.readonly = ko.observable(true);

		this.availableGroups = ko.observableArray([]);
		this.availableUsers = ko.observableArray([]);
		this.availableSpecialProperties = ko.observableArray([
			{ label: 'All Users', name: 'all' },
			{ label: 'Authenticated Users', name: 'authenticated' },
			{ label: 'Unauthenticated Users', name: 'unauthenticated' },
			{ label: 'Resource Owner', name: 'owner' }
		]);

		this.availablePrincipalTypes = ko.observableArray([
			{label: 'User', value: 'user'},
			{label: 'Group', value: 'group'},
			{label: 'Special Value', value: 'property'}
		]);
		this.selectedPrincipalType = ko.observable();
		
		this.selectedPrincipal = ko.observable();

		this.availableModes = ko.observableArray(['ALLOW', 'DENY']);
		this.selectedMode = ko.observable();
		
		this.index = ko.observable('1');

		this.availablePrivileges = ko.observableArray([]);
		this.selectedAvailablePrivileges = ko.observableArray([]);
		this.allPrivileges = ko.observableArray([
			'write-properties',
			'write-content',
			'bind',
			'unbind',
			'write',
			'unlock',
			'read-acl',
			'read-current-user-privilege-set',
			'read',
			'write-acl',
			'all'
		]);
		this.selectedAvailablePrivileges = ko.observableArray();
		this.selectedCurrentPrivileges = ko.observableArray();
		this.currentPrivileges = ko.observableArray();
		
		this.currentPrivileges.subscribe((nv) => {
			let ar = [];
			if (nv && nv.length)
				ar = this.allPrivileges().filter((i) => { return (nv.indexOf(i) == -1); });
			else
				ar = this.allPrivileges();
			this.availablePrivileges(ar);
		});
		this.currentPrivileges([]);

		this.title = ko.observable();

		this.availablePrincipals = ko.computed(() => {
			if (!this.selectedPrincipalType())
				return [];
			if (this.selectedPrincipalType().value == 'user')
				return this.availableUsers();
			else if (this.selectedPrincipalType().value == 'group')
				return this.availableGroups();
			else if (this.selectedPrincipalType().value == 'property')
				return this.availableSpecialProperties();
		});
	}

	load_ace(ace)
	{
		if (!ace)
		{
			this.readonly(false);
			return;
		}
		this.ace = ace;
		console.debug('EditAceDialog ace=', this.ace);
		if (this.ace.deny === false)
			this.selectedMode('ALLOW');
		else
			this.selectedMode('DENY');

		if (this.ace.hasOwnProperty('principal'))
		{
			if (this.ace.principal.type == 'user')
			{
				this.selectedPrincipalType(this.availablePrincipalTypes().find((x) => { return x.value == 'user'; }));
			}
			else if (this.ace.principal.type == 'group')
			{
				this.selectedPrincipalType(this.availablePrincipalTypes().find((x) => { return x.value == 'group'; }));
			}

			this.selectedPrincipal(this.availablePrincipals().find((x) => x.name == this.ace.principal.name));
		}
		else if (this.ace.hasOwnProperty('property'))
		{
			this.selectedPrincipalType(this.availablePrincipalTypes().find((x) => { return x.value == 'property'; }));
			// TODO
			this.selectedPrincipal(this.availablePrincipals().find((x) => x.name == this.ace.property));
		}

		let privilege_names = [];
		this.ace.privileges.forEach((priv) => {
			privilege_names.push(priv.name);
		});
		this.currentPrivileges(privilege_names);

		if (this.ace.hasOwnProperty('idx'))
			this.index(this.ace.idx);
	}

	remove_privileges ()
	{
		let curr_privs = this.currentPrivileges();
		let remove_privs = this.selectedCurrentPrivileges();
		curr_privs = curr_privs.filter( (i) => { return (!remove_privs.includes(i)); });
		this.currentPrivileges(curr_privs);
	}

	add_privileges ()
	{
		let curr_privs = this.currentPrivileges();
		if (!curr_privs)
			curr_privs = [];
		this.currentPrivileges(curr_privs.concat(this.selectedAvailablePrivileges()));
	}

	/**
	 * Handle click event for save button
	 */
	async save_click()
	{
		console.log('save clicked in edit-ace');
		let idx;
		try {
			idx = parseInt(this.index());
		} catch (e) {
			idx = null;
		}

		try {
			let data = {
				'resource-id': this.resource.id,
				add: [
					{
						type: this.selectedPrincipalType().value,
						name: this.selectedPrincipal().name,
						privileges: this.currentPrivileges(),
						mode: this.selectedMode(),
						idx: idx
					}
				]
			};

			let response = await Grape.fetches.postJSON('/grape-filestore/ace/save', data)
			
			if (response.status == 'OK')
				this.dialog.close(true);
			else
				Grape.alerts.alert({type: 'error', title: 'Forbidden', message: 'Permission denied to update Access Control List'});

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings)
	{
		this.bindings = bindings;
		this.name = 'EditACE'; // Unique dialog name
		if (bindings.ace)
			this.ace = bindings.ace;
		else
			this.ace = null;
		if (bindings.resource)
			this.resource = bindings.resource;
		this.viewModel = new DialogViewModel(this); // Name of the ViewModel defined above
	}

	async init ()
	{
		let response;
		let data;
		
		// TODO replace with cache
		response = await fetch('/api/record?table=v_users&schema=grape', { headers: {'accept': 'application/json'} });
		data = await response.json();
		let users = [];
		data.records.forEach((u) => {
			users.push({label: u.username, name: u.username});
		});
		this.viewModel.availableUsers(users);

		// TODO replace with cache
		response = await fetch('/api/record?table=v_access_roles&schema=grape', { headers: {'accept': 'application/json'} });
		data = await response.json();
		let groups = [];
		data.records.forEach((g) => {
			groups.push({label: g.role_name, name: g.role_name});
		});
		this.viewModel.availableGroups(groups);

		this.viewModel.load_ace(this.ace || null);
	}
}

export default {
	name: 'EditACE',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
};
