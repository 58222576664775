const template = `<div data-bind="visible: message(), class: status_class"><strong data-bind="html: status_title()"></strong> <span data-bind="html: message"></span></div>`;

class AlertMessageVM
{
	constructor(params)
	{
		console.debug('AlertMessage VMparams = ', params);

		this.status = params.status;
		this.status_title = params.title;
		this.message = params.message;

		this.status_class = ko.computed(() => {
			if (['success', 'info', 'warning', 'danger'].indexOf(this.status()) < 0)
				console.warn('AlertMessageVM status "' + this.status() + '" is not recognized');
			return 'ps-alert-' + this.status();
		});
	}
}

export default {
	name: 'alert-message',
	viewModel: AlertMessageVM,
	module_type: 'ko',
	template: template
}
