
import template from './filestore-dashboard.html';
import path from 'path-browserify';

class FSDashboardVM
{
	constructor(page)
	{
		const self = this;
		this.page = page;
		this.selected_tab = ko.observable('filebrowser');
		this.AvailableStores = ko.observableArray();
		this.selectedStore = ko.observable();
		this.cwd = ko.observable();

		this.selectedStore.subscribe((nv) => {
			if (!nv)
				return;
			if (!nv.mount_path.endsWith('/'))
				nv.mount_path += '/';
			self.cwd(nv.mount_path);

			localStorage.setItem('LastSelectedStoreName', nv.name);
		});

		this.xml_namespaces = ko.observableArray();
		this.properties = ko.observableArray();
	}

	ev_btnEditStore_click ()
	{
		this.show_EditFileStoreDialog(this.selectedStore());
	}

	ev_btnAddStore_click ()
	{
		this.show_EditFileStoreDialog(null); 
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#filestore_dashboard_nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});
		
		event.currentTarget.classList.add('active');
		
		this.selected_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	async update()
	{
		let data = await Grape.cache.get('FileStores'); 
		
		data.stores.forEach((s) => {
			s.label = s.name + ' (' + s.mount_path + ')';
		});
		
		this.AvailableStores(data.stores);

		if (localStorage.getItem('LastSelectedStoreName'))
		{
			let lhist = this.AvailableStores().find((x) => { return (x.name == localStorage.getItem('LastSelectedStoreName')); });
			this.selectedStore(this.AvailableStores().find((x) => { return (x.name == localStorage.getItem('LastSelectedStoreName')); }));
		}

		data = await Grape.fetches.getJSON('/api/record', {schema: 'filestore', table: 'xml_namespace'});
		this.xml_namespaces(data.records || []);
		
		data = await Grape.fetches.getJSON('/api/record', {schema: 'filestore', table: 'v_all_properties'});
		this.properties(data.records || []);
	}

	async btnEditRootCollection_click()
	{
		let href = this.selectedStore().mount_path;
		let d = await Grape.dialog.open('ViewResourceEntryDialog', {entry: { href: ko.observable(href) } });
	}

	async show_EditFileStoreDialog(store)
	{
		let result = await Grape.dialog.open('EditFileStore', {store: store});
		if (result)
		{
			Grape.cache.invalidate('FileStores');
			await this.update();
		}
	}
}

class FileStoreDashboardPage
{
	constructor(bindings, element)
	{
		this.viewModel = new FSDashboardVM(this);
		this.bindings = bindings;
		this.element = element;
	}

	init()
	{
		document.title = 'Dashboard - Filestore';
		this.viewModel.update();
	}
}

export default {
	route: '/grape-ui-dashboard/filestore',
	page_class: FileStoreDashboardPage,
	template: template,
	name: 'filestore-dashboard',
	title: 'Filestore',
	page_id: 'dashboard.filestore',
	icon: 'fa-regular fa-file-alt',
	idx: 999
};
