ko.bindingHandlers.fileSize = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		let valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

		let decimals = 2;
		if (ko.utils.unwrapObservable(allBindingsAccessor().decimals) !== undefined)
		{
			try {
				decimals = parseInt(ko.utils.unwrapObservable(allBindingsAccessor().decimals));
			} catch (err) {
				decimals = 2;
			}
		}

		let bytes = 0;
		try {
			if (valueUnwrapped)
				bytes = parseInt(valueUnwrapped);
		} catch (err) { bytes = 0; }

		if (isNaN(bytes))
			bytes = 0;

		let formatted = '0 Bytes';
		if (bytes > 0)
		{
			let k = 1024;
			let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			let i = Math.floor(Math.log(bytes) / Math.log(k));
			formatted = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
		}

		element.textContent = formatted;
	}
};
