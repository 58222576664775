
import template from './file-selector.html';
import path from 'path-browserify';

/**
 * File selector dialog
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;
		
		this.new_name = ko.observable(''); // new resource name
		this.new_path = ko.observable(''); // path to collection

		this.title = ko.observable('Select path'); // dialog title

		console.log('location=',dialog.bindings.location);

		
		if (dialog.bindings.title)
			this.title(dialog.bindings.title);

		this.multiselect = dialog.bindings.multiselect || false;
		this.create_new = dialog.bindings.create_new || false; // can a user enter non-existing resource path
		this.hide_navigation = dialog.bindings.hide_navigation || false;
		this.allowed_types = dialog.bindings.allowed_types || '*';
		this.allowed_names = dialog.bindings.allowed_names || '*';
		this.hide_directories = dialog.bindings.hide_directories || false;
		this.hide_files = dialog.bindings.hide_files || false;
		this.allow_collection_selection = dialog.bindings.allow_collection_selection || false;
		this.allow_regular_selection = dialog.bindings.allow_regular_selection || false;

		if (dialog.bindings.location)
		{
			this.new_path(dialog.bindings.location);

			if (this.create_new === false)
			{
			}
			else
			{
			}
		}
		else
		{
			this.new_path('/');
		}

	}

	/**
	 * Handle click event for save button
	 */
	save_click()
	{
		this.dialog.close(path.join(this.new_path(), this.new_name()));
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}

}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings)
	{
		this.bindings = bindings;
		this.name = 'FileSelector'; // Unique dialog name
		this.viewModel = new DialogViewModel(this); // Name of the ViewModel defined above

	}

	init () {
	}
}

export default {
	name: 'FileSelector',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
};
