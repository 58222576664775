
// Import dialogs

import EditACE from './edit-ace.js';
import EditFileStore from './edit-filestore.js';
import EditPropertyValue from './edit-property-value.js';
import EditResourceTypes from './edit-restypes.js';
import FileSelector from './file-selector.js';
import LockInfo from './lock-info.js';
import ViewEntry from './view-entry.js';
import ViewLocks from './view-locks.js';

export default [
	EditACE,
	EditFileStore,
	EditPropertyValue,
	EditResourceTypes,
	FileSelector,
	LockInfo,
	ViewEntry,
	ViewLocks
];

