
/**
 * This is an example of how to store custom values from a GET call to cache
 * @class CacheCustomLookup
 * @description Custom lookup cache
 * @usage Grape.cache.fetch('CustomLookup', (data) => { });
 */
export default {
	name: 'FileStores',
	options: {
		refresh: async (cb) => {
			let result = await Grape.fetches.getJSON('/grape-filestore/stores', {});

			if (result.status === 'OK')
				return result;
			else
				cb(new Error(d), []);
		}
	}
};
