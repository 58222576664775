
import template from './view-locks.html';

/**
 * ViewLocks dialog is a dialog that typically display the results from {DAV:}lockdiscovery, which looks like this:
 * {
  "depth": 1,
  "owner": {
    "description": "alice"
  },
  "lockid": 3,
  "lockroot": "/Cellar/index.html",
  "locktype": "write",
  "lockscope": "exclusive",
  "locktoken": "urn:uuid:5892cf02-3b62-dece-9452-e2804ee6ecdb",
  "direct": true
}
 */
class ViewLocksDialogVM
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog, locks)
	{
		this.dialog = dialog;
		
		this.locks = ko.observable(locks);
	}

	/**
	 * Handle click event for save button
	 */
	save_click()
	{
		this.dialog.close({
			type: this.values.type(),
			scope: this.values.scope(),
			depth: this.values.depth(),
			lifetime: this.values.lifetime()
		});
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings)
	{
		this.bindings = bindings;
		this.name = 'ViewLocks'; // Unique dialog name
		if (bindings.locks)
			this.locks = bindings.locks;
		else
			this.locks = [];
		this.viewModel = new ViewLocksDialogVM(this, this.locks); 
	}

	init () {

	}
}

export default {
	name: 'ViewLocks',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
};

