
import template from './edit-restypes.html';

/**
 * View Model
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;

		this.resource = dialog.resource;

		this.availableResourceTypes = ko.observableArray([]);
		this.selectedAvailableResourceTypes = ko.observableArray([]);
		this.allResourceTypes = ko.observableArray([
			{nsuri: 'DAV:', name: 'regular'},
			{nsuri: 'DAV:', name: 'collection'},
			{nsuri: 'DAV:', name: 'principal'}
		]);
		this.selectedAvailableResourceTypes = ko.observableArray();
		this.selectedCurrentResourceTypes = ko.observableArray();
		this.currentResourceTypes = ko.observableArray();
		
		this.currentResourceTypes.subscribe((nv) => {
			let ar = [];
			if (nv && nv.length)
				ar = this.allResourceTypes().filter((i) => { return (nv.indexOf(i) == -1); });
			else
				ar = this.allResourceTypes();
			this.availableResourceTypes(ar);
		});
		this.currentResourceTypes([]);

		if (this.resource)
		{
			const init = async () => {
				await this.resource.populate([{nsuri: 'DAV:', name: 'resourcetype'}]);
				let nv = [];
				let restype = this.resource.get('DAV:', 'resourcetype');
				if (typeof restype == 'string')
				{
					let r = this.allResourceTypes().find((x) => x.name == restype);
					if (r)
						nv.push(r);
				}
				else if (Array.isArray(restype))
				{
					for (let rt of restype)
					{
						let r = this.allResourceTypes().find((x) => x.nsuri == rt.nsuri && x.name == rt.name);
						if (r)
							nv.push(r);
					}
				}
				this.currentResourceTypes(nv);
			};
			init();
		}
	}

	remove_resource_types ()
	{
		let curr_privs = this.currentResourceTypes();
		let remove_privs = this.selectedCurrentResourceTypes();

		if (this.resource.collection() === false)
		{
			if (remove_privs.find((x) => x.nsuri == 'DAV:' && x.name == 'regular'))
			{
				alert('Cannot remove the {DAV:}regular resource type');
				return;
			}
		}
		else if (this.resource.collection() === true)
		{
			if (remove_privs.find((x) => x.nsuri == 'DAV:' && x.name == 'collection'))
			{
				alert('Cannot remove the {DAV:}collection resource type');
				return;
			}
		}


		curr_privs = curr_privs.filter( (i) => { return (!remove_privs.includes(i)); });
		this.currentResourceTypes(curr_privs);
	}

	add_resource_types ()
	{
		let curr_privs = this.currentResourceTypes();
		if (!curr_privs)
			curr_privs = [];
		let list = this.selectedAvailableResourceTypes();
		if (this.resource.collection() === true)
		{
			if (list.find((x) => x.nsuri == 'DAV:' && x.name == 'regular'))
			{
				alert('Cannot add this resource type for collections');
				return;
			}
		}
		else if (this.resource.collection() === false)
		{
			if (list.find((x) => x.nsuri == 'DAV:' && x.name == 'collection'))
			{
				alert('Cannot add this resource type for regular');
				return;
			}
		}

		this.currentResourceTypes(curr_privs.concat(this.selectedAvailableResourceTypes()));
	}


	/**
	 * Handle click event for save button
	 */
	async save_click()
	{
		let resource_types = this.currentResourceTypes();
		await this.resource.save_property('DAV:', 'resourcetype', resource_types);
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings)
	{
		this.bindings = bindings;
		this.name = 'EditResourceTypes'; // Unique dialog name
		if (bindings.resource)
			this.resource = bindings.resource;
		this.viewModel = new DialogViewModel(this); // Name of the ViewModel defined above

	}

	async init () {
	}
}

export default {
	name: 'EditResourceTypes',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
};

