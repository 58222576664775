
import template from './edit-filestore.html';

/**
 * View Model
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;

		this.name = ko.observable();
		this.mount_path = ko.observable();
		this.enforce_versioning = ko.observable(false);
		this.autoversion_options = ko.observableArray([
			{label: 'checkout-checkin', description: 'Requests are automatically preceded by a checkout and followed by a checkin operation'},
			{label: 'checkout', description: 'Requests are automatically preceded by a checkout operation'},
			{label: 'checkout-unlocked-checkin', description: 'Requests are automatically preceded by a checkout operation. If the resource is not write-locked, the request is automatically followed by a checkin operation'},
			{label: 'locked-checkout', description: 'When write-locked, the request is automatically preceded by a checkout'},
		]);
		this.autoversion = ko.observable(); // autoversion behaviour
		this.allow_custom_properties = ko.observable(false); // allow custom properties
		this.storage_location = ko.observableArray();

		this.status = ko.observable();
		this.status_title = ko.observable();
		this.status_message = ko.observable();

		this.saved_values = {};

		this.changed = ko.computed(() => {
			(k) => { return (this.saved_values[k] && this.saved_values[k] == this[k]()); }
			return (('name') || ('mount_path'));
		});

		this.saved = false;
	}

	/**
	 * Handle click event for save button
	 */
	async save_click()
	{
		try {
			let data = {
				name: this.name(),
				mount_path: this.mount_path(),
				enforce_versioning: this.enforce_versioning() || false,
				autoversion: this.autoversion().label,
				allow_custom_properties: this.allow_custom_properties() || false
			};

			let response = await Grape.fetches.postJSON('/grape-filestore/store', data)
			
			if (response.status == 'OK')
			{
				this.status('success');
				this.status_title('Saved');
				this.status_message('Settings has been saved successfuly.');
				this.saved = true;
			}
			else
			{
				this.status('danger');
				this.status_title('Error');
				this.status_message(response.message);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(this.saved);
	}
}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this); // Name of the ViewModel defined above
		this.name = 'EditFileStore'; // Unique dialog name

		if (bindings.store)
		{
			console.debug('EditFilestoreDialog store=', bindings.store);
			this.viewModel.name (bindings.store.name);
			this.viewModel.mount_path (bindings.store.mount_path);
			this.viewModel.storage_location (bindings.store.storage_location);
			this.viewModel.enforce_versioning (bindings.store.enforce_versioning);
			this.viewModel.autoversion(this.viewModel.autoversion_options().find((x) => x.label == bindings.store.autoversion));
			this.viewModel.allow_custom_properties(bindings.store.allow_custom_properties);

			this.viewModel.saved_values = bindings.store;
		}
	}

	init () {
	}
}

export default {
	name: 'EditFileStore',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
};

