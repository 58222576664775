
// COMPONENTS
import file_list from './file-list.js';
import file_browser from './file-browser.js';
import file_selector from './file-selector.js';
import alert_message from './alert-message.js';

export default [
	file_list,
	file_browser,
	file_selector,
	alert_message
];
