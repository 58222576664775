
import PropertyList from './PropertyList.js';
import path from 'path-browserify';

/**
 */
class ResourceVM
{
	constructor(FS, href)
	{
		this.FS = FS;
		/**
		 * Resource path (not including any host details)
		 * @type {Observable<string>}
		 */
		if (ko.isObservable(href))
			this.href = href;
		else
			this.href = ko.observable(href);

		this.href.subscribe((nv) => {
			this.name(path.basename(nv));
			if (nv)
				this.populate();
		});

		/**
		 */
		this.name = ko.observable('');

		/**
		 * Properties
		 */
		this.properties = new PropertyList();

		/**
		 * QNamed Properties
		 */
		this.qprops = ko.observable({});

		/**
		 * Children in the resource collection (only applicable if the resource is a collection resource)
		 * @type {ResourceEntry[]}
		 */
		this.children = ko.observableArray([]);

		/**
		 * Primary resource type - can be 'collection' or 'regular'
		 * @type {string}
		 */
		this.resourcetype = ko.observable(null);
		
		/**
		 * Will be true for collection resources
		 */
		this.collection = ko.observable(false);

		/**
		 * Versioning status. Should be NA, CO or CI
		 */
		this.versioning_status = ko.observable('');

		/**
		 * This property will be set if an error occured while loading data
		 * @type {Object}
		 */
		this.error = ko.observable();
		
		/**
		 * Selected
		 */
		this.selected = ko.observable(false);

		/**
		 * Lock token to include in write requests
		 */
		this.locktoken = ko.observable(null);

		/**
		 * Locks
		 */
		this.locks = ko.observableArray();

		/**
		 */
		this.has_locks = ko.observable(false);
		this.has_exclusive_locks = ko.observable(false);
		this.has_shared_locks = ko.observable(false);
		this.has_indirect_locks = ko.observable(false);
		this.has_direct_locks = ko.observable(false);

		this.can_read = ko.observable(false);
		this.can_write = ko.observable(false);
		this.can_bind = ko.observable(false);
		this.can_unbind = ko.observable(false);
		this.can_checkout = ko.observable(false);
		this.can_checkin = ko.observable(false);
		this.can_lock = ko.observable(false);
		this.can_unlock = ko.observable(false);
	
	}

	get(nsuri, name) { return this.properties.get(nsuri, name); }
	set(nsuri, name, value) { return this.properties.set(nsuri, name, value); }

	set_properties(properties)
	{
		this.properties.set_properties(properties);

		// Resource type
		if (this.get('DAV:', 'resourcetype') == 'collection')
			this.collection(true);
		else
			this.collection(false);

		// TODO if (properties.contains('DAV:', 'lockdiscovery'))
		this.update_lockinfo();

		// Privileges
		let current_user_privileges = this.get('DAV:', 'current-user-privilege-set') || [];

		if (current_user_privileges.indexOf('read') > -1)
			this.can_read(true);

		if (current_user_privileges.indexOf('write') > -1)
			this.can_write(true);

		if (current_user_privileges.indexOf('bind') > -1)
			this.can_bind(true);

		if (current_user_privileges.indexOf('unbind') > -1)
			this.can_unbind(true);

		// Versioned state

		let vs = this.get('PS:', 'versionedstate');
		if (vs && vs.state)
		{
			this.set('', 'version-status', vs.state);
			if (vs.state == 'CO')
				this.can_checkin(true);
			else if (vs.state == 'CI')
				this.can_checkout(true);
			else if (vs.state == 'NA')
				this.can_checkout(true);
			this.versioning_status(vs.state);
		}

	}

	async save_property(nsuri, name, value)
	{
		return this.save_properties([{nsuri: nsuri, name: name, value: value}]);
	}
	
	async save_properties(properties, remove_properties=[])
	{
		let options = {};
		if (this.locktoken())
			options['locktoken'] = this.locktoken();
		return this.FS.update(this.href(), properties, remove_properties, options);
	}

	property_value(nsuri, name)
	{
		let prop = this.properties.find((p) => { return (p.nsuri == nsuri && p.name == name); });
		if (!prop)
			return null;
		return prop.value;
	}

	async populate(properties=[])
	{
		if (!this.href())
			return;
		let list_result = await this.FS.list(this.href(), 1, properties);
		if (!list_result)
			throw new Error(`Error while listing "${this.href()}"`);

		if (typeof(list_result) === 'object' && list_result.error)
		{
			this.error = list_result.error;
			throw {code: this.error.code, message: `Error while listing "${this.href()}": ${this.error.message}`};
		}

		let children = [];
		for (let result of list_result)
		{
			if (result.full_path == this.href())
			{
				this.set_properties(result.properties);
			}
			else
			{
				let child = this.children().find((child) => { return child.href() == result.full_path; });
				if (!child)
					child = new ResourceVM(this.FS, result.full_path);
				child.set_properties(result.properties);
				child.set('ui:', 'name', path.basename(result.full_path));
				children.push(child);
			}
		}

		this.children(children);
	}

	populate_qprops(properties)
	{
		let qprops = {};
		for (let prop of properties)
		{
			let qname = `{${prop.nsuri}}${prop.name}`;
			qprops[qname] = prop.value;
			if (qname == '{DAV:}resourcetype')
			{
				this.resourcetype(prop.value);
				if (prop.value == 'collection')
					this.collection(true);
				else
					this.collection(false);
			}
		}
		this.qprops(qprops);
	}

	update_lockinfo()
	{
		// Locks
		let locks = this.get('DAV:', 'lockdiscovery') || [];
		if (locks.length)
		{
			this.has_locks(true);
			for (let lock of locks)
			{
				// Deal with cases where lockroot is for example '/dsds/dsds' but href() is '/dsds/dsds/'
				let lockroot = lock.lockroot;
				if (this.href().endsWith('/') && !lock.lockroot.endsWith())
					lockroot += '/';

				if (lockroot == this.href())
				{
					this.has_direct_locks(true);
					lock.direct = true;
				}
				else
				{
					this.has_indirect_locks(true);
					lock.direct = false;
				}
				
				if (lock.lockscope == 'exclusive')
					this.has_exclusive_locks(true);
				else if (lock.lockscope == 'shared')
					this.has_shared_locks(true);
			}
			this.can_lock(false);
		}
		else
		{
			this.has_locks(false);
			this.has_exclusive_locks(false);
			this.has_shared_locks(false);
			this.has_indirect_locks(false);
			this.has_direct_locks(false);
			this.can_lock(true);
		}

		this.locks(locks);

	}

	async report(report_nsuri, report_name, options)
	{
		return this.FS.report(this.href(), report_nsuri, report_name, options);
	}

	async checkout()
	{
		return this.FS.checkout(this.href());
	}

	async checkin()
	{
		return this.FS.checkin(this.href());
	}

	async lock(type, scope, depth, lifetime)
	{
		let lockinfo = {
			type: type,
			scope: scope,
			depth: depth,
			lifetime: lifetime
		};
		return this.FS.lock(this.href(), lockinfo);
	}

	async unlock(locktoken)
	{
		let result = await this.FS.unlock(this.href(), locktoken);
		if (locktoken === this.locktoken())
		{
			this.locktoken(null);
		}
	}
}

export default ResourceVM;

