
/**
 * Contains information about a single resource property entry
 * @class
 */
class Property
{
	constructor(nsuri, name, value)
	{
		this.nsuri = nsuri;
		this.name = name;
		this._value = value;

		this.allprop = false;
		this.implicit = false;
		this.live = false;
		this.protected = false;

		this.loaded = false;

		this.error = false;

		this.override = false;

		if (typeof value != 'undefined')
			this.loaded = true;
	}

	/**
	 * Sets the value of the property entry
	 */
	set value(val) { 
		this._value = val; 
		if (typeof val == 'undefined')
			this.loaded = false;
		else
			this.loaded = true;
	}
	/**
	 * Gets the value of the property entry. If no value has been set, an error will be thrown.
	 */
	get value() { 
		if (this.loaded)
			return this._value; 
		return undefined;
	}

	/**
	 * Sets the status of the property entry
	 */
	set status(status_code)
	{
		if (status_code == 'OK')
		{
			this.error = null;
			this.loaded = true;
		}
		else if (status_code == 'ENOTLOADED')
		{
			this.error = null;
			this.loaded = false;
		}
		else
		{
			this.error = status_code;
			this.loaded = false;
		}
	}

	/**
	 * Returns the status of the property entry
	 */
	get status()
	{
		if (!this.error)
			return 'OK';
		else
			return this.error;
	}

	/**
	 * Set from object
	 */
	set_from(obj)
	{
		let name = null;
		let nsuri = null;
		if (typeof obj === 'string')
		{
			name = obj;
		}
		else if (Array.isArray(obj))
		{
		}
		else if (typeof obj == 'object')
		{
		}

	}

	toJSON()
	{
		if (this.error) return {
			nsuri: this.nsuri,
			name: this.name,
			status: this.error
		}
		else if (this.loaded) return {
			nsuri: this.nsuri,
			name: this.name,
			value: this.value,
			status: this.status
		}
		else return {
			nsuri: this.nsuri,
			name: this.name,
			status: this.status
		}
	}
}

export default Property;

