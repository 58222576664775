
import Property from './Property.js';

/**
 * Contains a list of Property objects
 * @name PropertyList
 * @class
 */
class PropertyList
{
	constructor(props)
	{
		this.properties = [];
		this.xml_namespaces = {'DAV:': 'D'};

		this.set_properties(props);
	}

	/**
	 * Find and return a Property Entry
	 * @return {Property}
	 */
	find_property_entry(nsuri, name)
	{
		let prop;
		if (nsuri && nsuri.length && name && name.length)
			prop = this.properties.find((prop) => { return (prop.nsuri == nsuri && prop.name == name); });
		else if (nsuri && nsuri.length)
			prop = this.properties.find((prop) => { return (prop.name == nsuri); });
		else if (name && name.length)
			prop = this.properties.find((prop) => { return (prop.name == name); });

		return prop || null;
	}
	
	/**
	 * Find and return the value of a Property Entry
	 * @return {any}
	 */
	find_property_value(nsuri, name)
	{
		let prop = this.find_property_entry(nsuri, name);
		if (!prop)
			return null;
		return prop.value;
	}

	/**
	 * Sets a property value. Add if it does not exist.
	 * @param {string} nsuri -
	 * @param {string} name -
	 * @param {any} value -
	 * @return {Property}
	 */
	set_property_value(nsuri, name, value)
	{
		if (typeof nsuri == 'object' && typeof name == 'undefined')
			return this.set_property(nsuri);
		else
			return this.set_property({nsuri, name, value});
	}
	
	/**
	 * Sets property values
	 */
	set_property_values(properties)
	{
		properties.forEach((prop) => {
			this.set_property(prop);
		});
	}


	/**
	 * Sets a property. Add if it does not exist
	 */
	set_property(property)
	{
		let name, nsuri, value;

		if (property.hasOwnProperty('name'))
			name = property.name;
		else if (property.hasOwnProperty('qname'))
			name = property.qname;
		
		if (property.hasOwnProperty('nsuri'))
			nsuri = property.nsuri;
		
		if (property.hasOwnProperty('value') && typeof property['value'] != 'undefined')
			value = property.value;

		if (typeof name == 'undefined' && typeof nsuri === 'undefined' && Object.keys(property).length == 1)
		{
			name = Object.keys(property)[0];
			value = property[name];
		}

		if (typeof name == 'string' && typeof nsuri === 'undefined')
		{
			let re = /\{(.*)\}(.*)/;
			let matches = name.match(re);
			if (matches !== null && matches.length === 3)
			{
				nsuri = matches[1];
				name = matches[2];
			}
		}

		if (!name)
		{
			throw new Error('name is empty');
		}

		let re = this.find_property_entry(nsuri, name);
		if (!re)
		{
			re = new Property(nsuri, name, value);
			this.properties.push(re);
		}
		else if (typeof value != 'undefined')
			re.value = value;

		if (property.hasOwnProperty('allprop'))
			re.allprop = property.allprop;
		if (property.hasOwnProperty('live'))
			re.live = property.live;
		if (property.hasOwnProperty('implicit'))
			re.implicit = property.implicit;
		if (property.hasOwnProperty('protected'))
			re.protected = property.protected;
		if (property.hasOwnProperty('override'))
			re.override = property.override;
		if (property.hasOwnProperty('status'))
			re.status = property.status;
	}


	/**
	 * Sets properties. Add if it does not exist
	 */
	set_properties(properties=[])
	{
		if (!properties)
			return;

		if (Array.isArray(properties))
		{
			for (let i = 0; i < properties.length; i++)
			{
				let p = properties[i];
				this.set_property(p);
			}
		}
		else if (properties instanceof Object)
		{
			for (let [k,v] of Object.entries(properties))
			{
				let p = {};
				p[k] = v;
				this.set_property(p);
			}

		}
	}

	filter(...params) { return this.properties.filter(...params); }
	find(...params) { return this.properties.find(...params); }
	push(...params) { return this.properties.push(...params); }
	map(...params) { return this.properties.map(...params); }
	
	get (nsuri, name) { return this.find_property_value(nsuri, name); }
	set (nsuri, name, value) { return this.set_property_value(nsuri, name, value); }

	/**
	 * @typedef PropStat
	 * @type {object}
	 * @property {string} -
	 * @property {integer} status_code -
	 * @property {Property[]} props - Array with entries of Property
	 */

	/**
	 * Removes a property entry
	 */
	remove_property (nsuri, name) 
	{
		let idx = -1;
		for (let i = 0; i < this.properties.length && idx < 0; i++)
		{
			let prop = this.properties[i];
			if (prop.nsuri == nsuri && prop.name == name)
				idx = i;
		}
		if (idx >= 0)
			this.properties.splice(idx, 1);
		return idx;
	}

	/**
	 * Checks if property exists in the list
	 */
	has_property(nsuri, name)
	{
		let prop = this.find_property_entry(nsuri, name);
		if (!prop)
			return false;
		return true;
	}


	/**
	 * Gets a list of properties and values in the collection, grouped by status_code
	 * @returns {PropStat[]}
	 */
	properties_by_status(status_code)
	{
		return this.properties.filter((p) => { return (p.status == status_code); });
	}

	/**
	 * Gets a list of properties and values in the collection, grouped by status_code
	 * @returns {PropStat[]}
	 */
	properties_by_statuses(status_codes)
	{
		return this.properties.filter((p) => { return (status_codes.indexOf(p.status) > -1); });
	}


	/**
	 * Gets a list of unique status codes in the properties
	 */
	unique_status_codes()
	{
		let status_codes = [];
		for (let i = 0; i < this.properties.length; i++)
		{
			let prop = this.properties[i];
			if (status_codes.indexOf(prop.status) < 0)
				status_codes.push(prop.status);
		}
		return status_codes;
	}

	[Symbol.iterator]() {
		let index = 0;
		return {
			next: () => {
				if (index < this.properties.length)
					return {value: this.properties[index++], done: false};
				else
					return {done: true};
			}
		}
	}

	/*
	static sanitizePropertyList(properties)
	{
		const sanitizeQName(nsuri_in, name_in)
		{
			let nsuri = null;
			let name = null;
			if (!nsuri_in && name_in)
			{
			}
			else if (!name_in && !nsuri_in)
			{
			}
			else
			{
				
			}
			return {nsuri: nsuri, name: name};
		}

		const sanitizeEntry = (entries) => {
			const list = [];
			if (Array.isArray(entries))
			{
				for (let entry of entries) {
					list.push(entry);
				}
			}
			else if (entries instanceof Object)
			{
				if (entries.hasOwnProperty('name') && entries.hasOwnProperty('nsuri'))
				{
					let new_entry = {nsuri: '', name: ''};
					if (entries.hasOwnProperty('value'))
						new_entry.value = entries.value;
					list.push(new_entry);
				}
				else
				{
					for (const [k, v] of Object.entries(entries)) {
						let nsuri = null;
						let name = null;
						let r = sanitizeQName(k);
						let new_entry = {nsuri: '', name: ''};
						list.push(new_entry);
					}
				}
			}
			else if (typeof entries = 'string')
			{
			}
			else
				return entries;
			return list;
		};
		return sanitizeEntry(properties);
	}
	*/

	/**
	 * Get a list of all properties
	 */
	toSimpleArray()
	{
		let arr = [];
		for (let prop of this.properties)
		{
			arr.push({name: prop.name, nsuri: prop.nsuri, value: prop.value});
		}
		return arr;
	}

	toJSON()
	{
		return this.properties;
	}

	get length()
	{
		return this.properties.length;
	}
}

export default PropertyList;

