
import path from 'path-browserify';

class ResourceActions
{
	static async checkIn(resource)
	{
		let answer = await Grape.alerts.confirm({type: 'info', title: 'Checkin', message: 'Are you sure you want to checkin this resource?'});
		if (!answer)
			return null;
		try {
			let response = await fetch('/grape-filestore/check-in', {
				method: 'POST',
				headers: {'content-type': 'application/json'},
				body: JSON.stringify({full_path: resource.href()})
			});
			let data = await response.json();
			console.debug(data);
		} catch (err) {
			Grape.alerts.alert({type: "error", title: "Error", message: err});
			return null;
		}
		Grape.alerts.alert({type: 'success', title: 'Checked in', message: `The resource located at ${resource.href()} has been checked in`});
		return true;
	}

	static async lock(resource, lockinfo=null)
	{
		if (!lockinfo)
		{
			lockinfo = await Grape.dialog.open('LockInfoInput', {lock: null});
		}

		if (lockinfo)
		{
			let lock_result;
			try {
				lock_result = await resource.lock(lockinfo.type, lockinfo.scope, lockinfo.depth, lockinfo.lifetime);
				console.debug('lock_result=',lock_result);
			} catch (err) {
				if (err.status === 423)
				{
					Grape.alerts.alert({type: 'danger', title: 'Cannot lock', message: `The resource at ${resource.href()} or one of its members is locked`});
				}
				else
				{
					console.error(err);
					Grape.alerts.alert({type: 'danger', title: 'Cannot lock', message: `Unable to lock ${resource.href()} status=${err.status}`});
				}
			}
		}
		return true;
	}

	static async view (resource)
	{
		await Grape.dialog.open('ViewResourceEntryDialog', {entry: resource});
		return;
	}

	static async delete(resource)
	{
		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Confirm Delete', message: 'Are you sure you want to delete the resource entry located at ' + resource.href() + '?'});
		if (!answer)
			return;

		let response = await fetch(resource.href(), {method: 'DELETE', headers: {'content-type': 'application/json'}});
		if (response.ok)
		{
			//let data = await response.json();
			Grape.alerts.alert({type: 'success', title: 'Deleted', message: 'The resource located at ' + resource.href() + ' has been deleted'});
			return true;
		}
		else
		{
			if (response.status === 423)
			{
				Grape.alerts.alert({type: 'error', title: 'Locked', message: `Cannot delete the resource at "${resource.href()}" because it is locked`});
			}
			else
			{
				Grape.alerts.alert({type: 'error', title: 'Locked', message: `Cannot delete the resource at "${resource.href()}" because it is locked`});
			}
		}
	}

	static async checkOut(resource)
	{
		let answer = await Grape.alerts.confirm({type: 'info', title: 'Checkout', message: 'Are you sure you want to checkout this resource?'});
		if (!answer)
			return null;
		try {
			let response = await fetch(resource.href(), {
				method: 'CHECKOUT'
			});
			let data = await response.json();
			console.debug(data);
		} catch (err) {
			Grape.alerts.alert({type: "error", title: "Error", message: err});
			return null;
		}
		Grape.alerts.alert({type: 'success', title: 'Checked out', message: `The resource located at ${resource.href()} has been checked out`});
		return true;
	}

	static async copy(resource)
	{
		/*
		let bindings = {
			location: resource.href(),
			title: `Select path for copy of "${resource.href()}"`
		};
		let options = {
		};
		let result = await Grape.dialog.open('FileSelector', bindings, options);

		if (!result)
			return;

		let dest_path = path.normalize(result.trim());

		if (!dest_path.startsWith('/'))
		{
			Grape.alerts.alert({type: 'danger', title: 'Error', message: 'That is not a valid path'});
			return;
		}
		*/

		
		let dest_path = await Grape.alerts.prompt({type: 'success', title: 'Copy', message: `Please enter copy destination for "${path.basename(resource.href())}":`, has_input: resource.href()});

		if (!dest_path)
			return;
		if (path.normalize(dest_path.prompt) == path.normalize(resource.href()))
		{
			Grape.alerts.alert({
				type: 'error',
				title: 'Error',
				message: 'Duplicate file name forbidden'
			});
			return;
		}

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Copy', message: `Please confirm copy of "${resource.href()}" to "${dest_path.prompt}"?`});
		if (!answer)
			return null;

		try {
			let response = await fetch(resource.href(), {method: 'COPY', headers: {'content-type': 'application/json', destination: dest_path.prompt }});
			if (!response.ok)
			{
				let err = new Error(`Response failed: ${response.statusText}`);
				err.code = response.statusText;
				err.response = response;
				throw err;
			}
			else
			{
				let data = await response.json();
				console.debug(data);
			}
		} catch (err) {
			if (err.response?.statusText == 'Precondition Failed')
			{
				Grape.alerts.alert({
					type: 'error',
					title: 'Target exists',
					message: 'Cannot copy. Target path already exists'
				});
			}
			else
			{
				console.error(err);
				Grape.alerts.alert({type: "error", title: "Error", message: err});
			}

			return;
		}
		Grape.alerts.alert({type: 'success', title: 'Copied', message: `The resource located at ${resource.href()} has been copied to ${dest_path.prompt}`});
		return true;
	}

	static async rename(resource)
	{
		let answer = await Grape.alerts.prompt({type: 'success', title: 'Rename', message: `Please enter a new name for "${path.basename(resource.href())}":`, has_input: path.basename(resource.href())});
		if (!answer)
			return;

		try {
			let new_path = path.join(path.dirname(resource.href()), answer.prompt);
			let response = await fetch(resource.href(), {method: 'MOVE', headers: {'content-type': 'application/json', destination: new_path}});
			if (!response.ok)
			{
				let err = new Error(`Response failed: ${response.statusText}`);
				err.code = response.statusText;
				err.response = response;
				throw err;
			}
		} catch (err) {
			if (err.response?.statusText == 'Precondition Failed')
			{
				Grape.alerts.alert({
					type: 'error',
					title: 'Target exists',
					message: 'Cannot copy. Target path already exists'
				});
			}
			else
			{
				console.error(err);
				Grape.alerts.alert({type: "error", title: "Error", message: err});
			}

			return;
		}
		Grape.alerts.alert({type: 'success', title: 'Renamed', message: 'The resource located at ' + resource.href() + ' has been renamed'});
		return true;
	}

	static async move(resource)
	{
		let answer = await Grape.alerts.prompt({
			type: 'success',
			title: 'Move',
			message: 'Please enter a move destination:',
			has_input: resource.href()
		});
		if (!answer)
			return;

		try {
			let response = await fetch(resource.href(), {method: 'MOVE', headers: {'content-type': 'application/json', destination: answer.prompt}});
			if (!response.ok)
			{
				let err = new Error(`Response failed: ${response.statusText}`);
				err.code = response.statusText;
				err.response = response;
				throw err;
			}
		} catch (err) {
			if (err.response?.statusText == 'Precondition Failed')
			{
				Grape.alerts.alert({
					type: 'error',
					title: 'Target exists',
					message: 'Cannot move. Target path already exists'
				});
			}
			else
			{
				console.error(err);
				Grape.alerts.alert({type: "error", title: "Error", message: err});
			}

			return;

		}
		Grape.alerts.alert({type: 'success', title: 'Moved', message: 'The resource located at ' + resource.href() + ' has been moved'});
		return true;
	}

	static async downloadContent(resource)
	{
		//resource.href();
		window.open(resource.href(), '_blank');
	}
	
	static async openContent(resource)
	{
		window.open(resource.href(), '_blank');
	}
}

export default ResourceActions;
