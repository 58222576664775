ko.bindingHandlers.objectString = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		let valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

		function object2string(obj) {
			let lns = [];
			if (Array.isArray(obj))
			{
				for (const v of obj)
					lns.push(object2string(v));
				return '( ' + lns.join(', ') + ' )';
			}
			else if (obj instanceof Object)
			{
				for (const [k, v] of Object.entries(obj))
					lns.push(`${k}: ${object2string(v)}`);
				return '{ ' + lns.join('; ') + ' }';
			}
			else if (obj.toString instanceof Function)
				return obj.toString();
			else
				return obj;
		}

		let formatted = '';
		if (valueUnwrapped)
			formatted = object2string(valueUnwrapped);
		else
			formatted = '';

		element.textContent = formatted;
	}
};
