ko.bindingHandlers.yesNo = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		let valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

		// Colors
		let formatted = '';
		if (valueUnwrapped)
			formatted = '<span class="ps-label-success">' + 'Yes' + '</span>';
		else
			formatted = '<span class="ps-label-danger">' + 'No' + '</span>';

		element.innerHTML = formatted;
	}
};
