
/* Application/Library entry-point */

import appcaches from './caches/index.js';
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';

// Filestore Server Settings Dashboard Page
import DashboardPage from './pages/filestore-dashboard.js';
Grape.pages.add_page(DashboardPage);
Grape.registry.addItem(
	'PSServerDashboardPages',
	`${DashboardPage.title}`,
	{
		title: DashboardPage.title,
		icon: DashboardPage.icon,
		page_id: DashboardPage.page_id,
		idx: DashboardPage.idx
	}
);

// Other components
import './components/knockout-filesize.js';
import './components/knockout-yesNo.js';
import './components/knockout-objectString.js';

window.Grape.component.registerComponents(components);
window.Grape.dialog.registerDialogs(dialogs);
window.Grape.pages.registerPages(pages);
window.Grape.cache.registerCaches(appcaches);
