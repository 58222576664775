
import template from './lock-info.html';

/**
 * View Model
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;

		this.lock_scopes = ko.observableArray([{title: 'Exclusive', value: 'exclusive'}, {title: 'Shared', value: 'shared'}]);
		this.lock_types = ko.observableArray([{title: 'Write', value: 'write'}]);
		this.lock_depths = ko.observableArray([
			{title: 'This resource only', value: '0'},
			{title: 'This resource and its direct members only', value: '1'},
			{title: 'This resource and all its members', value: 'infinite'}
		]);
		this.lock_lifetimes = ko.observableArray([
			{title: 'Infinite', value: 'infinite'},
			{title: '1 Day', value: 86400},
			{title: '1 Week', value: 86400 * 7},
			{title: '1 Month', value: 86400 * 30}
		]);

		this.values = {
			type: ko.observable(),
			depth: ko.observable(),
			scope: ko.observable(),
			lifetime: ko.observable()
		};
	}

	/**
	 * Handle click event for save button
	 */
	save_click()
	{
		this.dialog.close({
			type: this.values.type(),
			scope: this.values.scope(),
			depth: this.values.depth(),
			lifetime: this.values.lifetime()
		});
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class DialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings)
	{
		this.bindings = bindings;
		this.name = 'LockInfo'; // Unique dialog name
		if (bindings.lock)
			this.lock = bindings.lock;
		else
			this.lock = null;
		this.viewModel = new DialogViewModel(this); // Name of the ViewModel defined above

	}

	init () {

	};
}

export default {
	name: 'LockInfoInput',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
};

