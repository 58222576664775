
/**
 * Describes a resource entry
 */
class FileStoreHTTP
{
	constructor()
	{

	}

	async list(full_path, depth, properties) {
		let response = await fetch(full_path, {
			method: 'PROPFIND', 
			cache: 'no-cache',
			headers: {
				'content-type': 'application/json',
				'Depth': depth
			},
			body: JSON.stringify(properties)
		});

		if (!response.ok)
		{
			this.error = {code: response.status, message: response.statusText};
			return {error: this.error};
		}

		let data = [];
		try {
			data = await response.json();
		} catch (err) {
			this.error = err;
			console.error(err);
			return {error: this.error};
		}

		console.log(data);
		return data;
	}


	async update(full_path, set_properties=[], remove_properties=[], options={}) {
		let headers = {
			'content-type': 'application/json'
		};
		if (options.locktoken)
		{
			headers['If'] = `(<${options.locktoken}>)`;
		}
		let response = await fetch(full_path, {
			method: 'PROPPATCH', 
			cache: 'no-cache',
			headers: headers,
			body: JSON.stringify({set: set_properties, remove: remove_properties, options: options})
		});

		if (!response.ok)
		{
			this.error = {code: response.status, message: response.statusText};
			return;
		}

		let data = [];
		try {
			data = await response.json();
		} catch (err) {
			this.error = err;
			console.error(err);
			return;
		}

		console.log('update result', data);
		return data;
	}


	async report (full_path, report_nsuri, report_name, options) {
		let response = await fetch(full_path, {
			method: 'REPORT', 
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({nsuri: report_nsuri, name: report_name, options: options})
		});

		if (!response.ok)
		{
			this.error = {code: response.status, message: response.statusText};
			return;
		}

		let data = [];
		try {
			data = await response.json();
			data = JSON.parse(data);
		} catch (err) {
			this.error = err;
			console.error(err);
			return;
		}

		console.log('report data=', data);
		return data;
	}

	async checkout (full_path) {
		let response = await fetch(full_path, {
			method: 'CHECKOUT', 
			cache: 'no-cache',
			headers: {
				'content-type': 'application/json'
			}
		});

		if (!response.ok)
		{
			this.error = {code: response.status, message: response.statusText};
			return;
		}

		let data = [];
		try {
			data = await response.json();
			console.log('checkout data=', data);
		} catch (err) {
			this.error = err;
			console.error(err);
			return;
		}

		return data;

	}

	async checkin (full_path) {
		let response = await fetch('/grape-filestore/check-in', {
			method: 'POST', 
			cache: 'no-cache',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({full_path: full_path})
		});

		if (!response.ok)
		{
			this.error = {code: response.status, message: response.statusText};
			return;
		}
			
		let data = {};
		if (response.bodyUsed === false)
		{
			try {
				data = await response.json();
			} catch (err) {
				this.error = err;
				console.error(err);
				return;
			}
		}

		return data;
	}

	async lock(full_path, lockinfo)
	{
		let response = await fetch(full_path, {
			method: 'LOCK', 
			cache: 'no-cache',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify(lockinfo)
		});

		if (!response.ok)
		{
			let err = new Error(`${response.statusText || 'Error in HTTP request'} status=${response.status}`);
			err.status = response.status;
			console.log('response=',response);
			throw err;
		}

		let data = [];
		data = await response.json();
		data = JSON.parse(data);
		return data;
	}

	async unlock(full_path, locktoken)
	{
		if (!locktoken)
			throw new Error('Locktoken is empty');
		let response = await fetch(full_path, {
			method: 'UNLOCK', 
			cache: 'no-cache',
			headers: {
				'Lock-token': `<${locktoken}>`
			}
		});

		if (!response.ok)
		{
			this.error = {code: response.status, message: response.statusText};
			return;
		}

		return true;
	}
}

export default FileStoreHTTP;

