
import template from './file-browser.html';
import FileStoreHTTP from '../lib/FileStoreHTTP.mjs';
import ResourceVM from '../lib/ResourceVM.mjs';
import ResourceActions from '../lib/ResourceActions.mjs';
import path from 'path-browserify';

/**
 * Component View Model
 * Usage: <file-browser params="current_path: ko.observable(), enable_navigation: true|false, enable_mkdir: true|false, icon_url_function: FUNC, fetch_additional_props: [{nsuri: 'DAV:', name: ''}]"></file-browser>
 */
class FileBrowserViewModel
{
	/**
	 * Component constructor
	 */
	constructor(params)
	{
		console.debug('FileBrowserViewModel params=', params);

		/**
		 * @memberof PageViewModel
		 * @type {Observable} loading - Page busy loading
		 */
		this.loading = ko.observable(true);
		this.ready = ko.observable(false);

		this.FS = new FileStoreHTTP();

		this.status = ko.observable();
		this.status_title = ko.observable();
		this.status_message = ko.observable();

		// Parameter: show_columns 
		this.show_columns = ko.observableArray(['name', 'owner', 'type', 'lastmodified', 'size']);
		if (params.show_columns && Array.isArray(params.show_columns))
			this.show_columns(params.show_columns);

		// Parameter: fetch_additional_props
		this.fetch_additional_props = [];
		if (params.fetch_additional_props && Array.isArray(params.fetch_additional_props))
			this.fetch_additional_props = params.fetch_additional_props;

		// Parameter: enable_navigation
		this.enable_navigation = ko.observable(true);
		if (params.enable_navigation === false)
			this.enable_navigation(false);

		// Parameter: enable_mkdir
		this.enable_mkdir = ko.observable(true);
		if (params.enable_mkdir === false)
			this.enable_mkdir(false);

		// Parameter: current_path
		if (params.current_path)
			this.current_path = params.current_path;
		else
			this.current_path = ko.observable();
		
		this.current_path_list = ko.computed(() => {
			if (!this.current_path())
				return [];

			let parts = this.current_path().split('/');
			let res = [];
			let href = '/';
			parts.forEach((p) => {
				if (p == '')
					return;
				href = path.join(href, p);
				res.push({ 
					name: p,
					href: (href != '/') ? (href + '/') : href
				});
			});
			return res;
		}, this);
		
		this.resource = ko.observable(null);
		this.children = ko.observableArray([]);
		this.new_dir_name = ko.observable('');

		this.checked_resources = ko.observableArray([]);
		this.is_all_checked = ko.observable(false);

		this.is_all_checked.subscribe((nv) => {
			this.children().forEach((fe) => {
				fe.is_checked(nv);
			});
		});

		this.file_uploaded = () => {
			Grape.alerts.alert({type: 'success', title: 'Success', message: 'File(s) have been uploaded successfuly'});
			this.update();
		}
		this.breadcrumb_click = (item) => { 
			this.current_path(item.href);
		};

		this.current_path.subscribe((nv) => {
			console.log('Current Path = ', nv);
			this.update();
		});

		this.update();
	}

	async update()
	{
		if (!this.current_path() || this.current_path() == '')
		{
			console.debug('this.current_path() is empty');
			return;
		}

		this.checked_resources([]);
		this.is_all_checked(false);

		let slowtimer = setTimeout(() => { 
			this.children([]);
			this.status('info');
			this.status_title('');
			this.status_message('Loading file list');
			this.ready(false); 
		}, 250); // show loading message after 250ms


		let fetch_props = [
			{nsuri: 'DAV:', name: 'resourcetype'},
			{nsuri: 'DAV:', name: 'iscollection'},
			{nsuri: 'DAV:', name: 'resource-id'},
			{nsuri: 'DAV:', name: 'getcontentlength'},
			{nsuri: 'DAV:', name: 'getcontenttype'},
			{nsuri: 'DAV:', name: 'getlastmodified'},
			{nsuri: 'DAV:', name: 'creator-displayname'},
			{nsuri: 'DAV:', name: 'creationdate'},
			{nsuri: 'DAV:', name: 'lockdiscovery'},
			{nsuri: 'DAV:', name: 'current-user-privilege-set'},
			{nsuri: 'PS:', name: 'versionedstate'},
			{nsuri: 'PS:', name: 'icon'}
		];

		let resource = new ResourceVM(this.FS, this.current_path());

		console.log('Resource = ', resource);

		try {
			await resource.populate(fetch_props);

			if (resource.error)
			{
				// TODO
			}

			clearTimeout(slowtimer);

			let children = [];
			
			for (let child of resource.children())
			{
				child.lock_status = null;
				
				let icon = child.get('PS:', 'icon');
				if (icon && icon.length)
					child.icon = '<img src="' + icon + '" style="height: 1.5em; width: 1.5em;" />';
				else
				{
					if (child.get('DAV:', 'iscollection') == '1')
						child.icon = '<span class="fa-regular fa-folder-open"></span>';
					else
						child.icon = '<span class="fa-regular fa-file"></span>';
				}

				child.is_checked = ko.observable(false);
				child.is_checked.subscribe((nv) => {
					if (nv)
						this.checked_resources.push(child);
					else
						this.checked_resources.splice(this.checked_resources.indexOf(child), 1);
				});

				child.set('', 'basename', path.basename(child.href()));

				children.push(child);
			}
			this.children (children);

			this.resource(resource);
			this.status_message('');
			this.ready(true);

		} catch (err) {
			console.error(err);
			
			clearTimeout(slowtimer);

			this.ready(false);
			this.status('danger');
			
			if (err.code)
				this.set_error_status(err.code, err.message);
			else
				this.status_message('Error: ' + err.message);
		}
	}

	open_entry(re)
	{
		if (re.get('DAV:', 'resourcetype') == 'collection')
			this.current_path(re.href());
		else
			window.open(re.href(), '_blank');
	}

	async view_entry(re)
	{
		let d = await Grape.dialog.open('ViewResourceEntryDialog', {entry: re});
		this.update();
	}
	
	async btnUnlock_click(resource)
	{
		let result = await ResourceActions.unlock(resource);
		if (result === true)
			await this.update();
	}

	async btnLock_click(resource)
	{
		let result = await ResourceActions.lock(resource);
		if (result === true)
			await this.update();
	}

	async btnDelete_click(resource)
	{
		let result = await ResourceActions.delete(resource);
		if (result === true)
			await this.update();
	}
	
	async btnCopy_click(resource)
	{
		let result = await ResourceActions.copy(resource);
		if (result === true)
			await this.update();
	}
	
	async btnRename_click(resource)
	{
		let result = await ResourceActions.rename(resource);
		if (result === true)
			await this.update();
	}
	
	async btnMove_click(resource)
	{
		let result = await ResourceActions.move(resource);
		if (result === true)
			await this.update();
	}

	async btnCheckIn_click(resource)
	{
		let result = await ResourceActions.checkIn(resource);
		if (result === true)
			await this.update();
	}

	async btnCheckOut_click(resource)
	{
		let result = await ResourceActions.checkOut(resource);
		if (result === true)
			await this.update();
	}

	async btnDownloadContent_click(resource)
	{
		let result = await ResourceActions.downloadContent(resource);
		if (result === true)
			await this.update();
	}
	
	async btnOpenContent_click(resource)
	{
		let result = await ResourceActions.openContent(resource);
		if (result === true)
			await this.update();
	}

	async create_new_dir()
	{
		if (this.new_dir_name() === '')
		{
			Grape.alerts.alert({type: 'warning',title: 'Directory Empty!',message: 'Please enter a directory name before clicking "Create"!'});
			return;
		}

		try {
			const new_dir = path.join(this.current_path(), this.new_dir_name());
			const response = await fetch(new_dir, {
				method: 'MKCOL',
				headers: {
					'Content-Type': 'application/json'
				}
			});
			
			if (!response.ok)
			{
				let error = await response.json();
				Grape.alerts.alert({type: 'error', title: 'Something went wrong', message: error.message || 'Unable to create directory'});

				return;
			}
	
			let data = await response.json();
			console.debug(data);
			
			Grape.alerts.alert({type: 'success',title: 'Directory created',message: 'Created successfully'});

			this.new_dir_name('');
			this.update();
		} catch (error) {
			console.error('Error:', error);
		}
	}

	set_error_status(statuscode, message = '')
	{
		if (statuscode == 403)
		{
			this.status_title('Forbidden!');
			this.status_message('You do not have access permission to this resource.');
		}
		else if (statuscode == 404)
		{
			this.status_title('Not found!');
			this.status_message('The resource does not exist.');
		}
		else if (statuscode >= 500)
		{
			this.status_title('Server error!');
			this.status_message('A server error has occurred!');
		}
		else if (statuscode >= 400)
		{
			this.status_title('Bad request!');
			this.status_message('The server reported a bad request (status=' + statuscode + ').');
		}
		else
		{
			this.status_title('Error (' + statuscode + ')');
			this.status_message('Error while loading resource information: ' + message);
		}
	}

	ondragover(ctx, event)
	{
		event.currentTarget.style.background = "lightblue";
		event.preventDefault();
	}

	async ondrop(ctx, event)
	{
		event.preventDefault();
		
		event.currentTarget.style.background = null;

		const upload_file = async (file) => {
			let full_path = path.join(this.current_path(), file.name);
			let response = await fetch(full_path, {method: 'PUT', body: file}); //headers: {'content-type': 'application/json'}});
			if (!response.ok)
				throw new Error(`${response.statusText}: Unable to upload to "${full_path}"`);

			let data = await response.text();
			console.debug(data);
			return `Uploaded "${full_path}"`;
		};

		let upload_promises = [];

		this.ready(false);

		if (event.originalEvent?.dataTransfer?.items)
			for (let item of event.originalEvent.dataTransfer.items)
				if (item.kind === 'file')
					upload_promises.push(upload_file(item.getAsFile()));
		else if (event.originalEvent?.dataTransfer?.files)
			for (let file of event.originalEvent.dataTransfer.files)
				upload_promises.push(upload_file(file));
		else 
			return;

		let results = await Promise.allSettled(upload_promises);
		// TODO do something with results
		console.log('Upload Results =', results);

		await this.update();
	}

	ondragleave(ctx, event)
	{
		event.currentTarget.style.background = null;
		event.preventDefault();
	}
}

export default {
	name: 'file-browser',
	viewModel: FileBrowserViewModel,
	module_type: 'ko',
	template: template
};
