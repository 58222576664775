
import template from './filestore-browser.html';
import Resource from '../lib/ResourceVM.mjs';
import FileStoreHTTP from '../lib/FileStoreHTTP.mjs';
import path from 'path-browserify';

class PSFileBrowserViewModel
{
	constructor(page)
	{
		this.page = page;
		this.FS = new FileStoreHTTP();
		this.current_path = ko.observable('/DAV');
		this.user_home_dir = ko.observable(Grape.currentSession?.user?.settings?.['filestore.home'] || '/DAV');
		this.shortcuts_list = ko.observableArray([]);
	}

	linkClick (href, resourcetype)
	{
		if (resourcetype === 'regular')
		{
			let link = document.createElement('a');
			link.href = href;
			link.download = href.split('/').pop();
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
		else
			this.current_path(href);
	}
}

class PSFileBrowserPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new PSFileBrowserViewModel(this);
		this.name = 'PSFileBrowserPageClass';
	}

	init ()
	{
		let lasturl = localStorage.getItem('filestore.file-browser-last-url');
		if (lasturl)
			this.viewModel.linkClick(lasturl);

		this.viewModel.current_path.subscribe((nv) => {
			if (nv && localStorage.getItem('filestore.file-browser-last-url') != nv)
				localStorage.setItem('filestore.file-browser-last-url', nv);
		});

		this.get_shortcuts();
	}

	async get_shortcuts ()
	{
		let directories = await this.fetch_dirs('/DAV/shortcuts/');

		directories.forEach((dir) => {
			this.add_shortcut(dir);
		});
	}

	async fetch_dirs (dir_path)
	{
		try {
			let resource = new Resource(this.viewModel.FS, dir_path);
			await resource.populate(['{DAV:}resourcetype', '{PS:}icon', '{DAV:}href'], 1);

			return resource.children().filter(child => child.href !== resource.href).map(child => {
				let href = child.href();
				let basename = path.basename(href);
				return {
					href: href,
					icon: child.get('PS:', 'icon') || '',
					resourcetype: child.get('DAV:', 'resourcetype') || 'collection',
					basename: basename
				};
			});

		} catch (error) {
			console.error('Error fetching directories:', error);
			return [];
		}
	}

	add_shortcut (dir)
	{
		this.viewModel.shortcuts_list.push({
			href: dir.href,
			icon: dir.icon ? `<img src="${dir.icon}" style="height: 2em; width: 2em;" />` : '',
			resourcetype: dir.resourcetype,
			basename: dir.basename
		});
	}
}

export default {
	route: '[/]ui/ps-file-browser',
	page_class: PSFileBrowserPageClass,
	template: template
};
