
import template from './file-list.html';

class FileListVM
{
	constructor(params)
	{

		this.current_directory = ko.observable();
		this.current_files = ko.observableArray();

		this.current_directory.subscribe(this.refresh_file_list);
	}

	refresh_file_list(cb)
	{
		this.storage.list(this.current_directory(), (err, entries) => {
			if (err)
			{
				// TODO
			}

			entries.forEach((e) => {});
			this.current_entries(entries);
		});
	}

	file_entry_click(entry)
	{
		console.log(entry);
		alert('click!');
	}
}

export default {
	name: 'file-list',
	viewModel: FileListVM,
	module_type: 'ko',
	template: template
};
