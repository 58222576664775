
import template from './file-selector.html';
import ResourceVM from '../lib/ResourceVM.mjs';
import FileStoreHTTP from '../lib/FileStoreHTTP.mjs';
import path from 'path-browserify';

/**
 * @type
 * @name FileSelectorParams
 */

class FileSelectorVM
{
	constructor(params)
	{
		this.FS = new FileStoreHTTP();

		this.options = {
			dirname: ko.observable(''),
			basename: ko.observable('')
		};

		if (params.hasOwnProperty('dirname'))
			if (ko.isObservable(params.dirname))
				this.options.dirname = params.dirname;
			else
				this.options.dirname(params.dirname);

		if (params.hasOwnProperty('basename'))
			if (ko.isObservable(params.basename))
				this.options.basename = params.basename;
			else
				this.options.basename(params.basename);

		this.options.dirname.subscribe((nv) => {
			this.update();
		});

		this.current_full_path = ko.computed(() => {
			return path.join(this.options.dirname(), this.options.basename());
		}, this);

		this.current_path_list = ko.computed(() => {
			if (!this.options.dirname())
				return [];

			let parts = this.options.dirname().split('/');
			let res = [];
			let full_path = '/';
			parts.forEach((p) => {
				if (p == '')
					return;
				full_path = path.join(full_path, p);
				res.push({ 
					name: p,
					full_path: path.join(full_path, '/')
				});
			});
			return res;
		}, this);

		this.resource = ko.observable();

		if (params.hasOwnProperty('hide_navigation'))
			if (ko.isObservable(params.hide_navigation))
				this.hide_nav = params.hide_navigation;
			else
				this.hide_nav = ko.observable(params.hide_navigation);
		else
			this.hide_nav = ko.observable(false);

		if (params.hasOwnProperty('allowed_types'))
			if (ko.isObservable(params.allowed_types))
				this.allowed_types = params.allowed_types;
			else
				this.allowed_types = ko.observable(params.allowed_types);
		else
			this.allowed_types = ko.observable('*/*');

		if (params.hasOwnProperty('allowed_names'))
			if (ko.isObservable(params.allowed_names))
				this.allowed_names = params.allowed_names;
			else
				this.allowed_names = ko.observable(params.allowed_names);
		else
			this.allowed_names = ko.observable('*.*');

		if (params.hasOwnProperty('hide_directories'))
			if (ko.isObservable(params.hide_directories))
				this.hide_directories = params.hide_directories;
			else
				this.hide_directories = ko.observable(params.hide_directories);
		else
			this.hide_directories = ko.observable(false);

		if (params.hasOwnProperty('hide_files'))
			if (ko.isObservable(params.hide_files))
				this.hide_files = params.hide_files;
			else
				this.hide_files = ko.observable(params.hide_files);
		else
			this.hide_files = ko.observable(false);

		if (params.hasOwnProperty('allow_collection_selection'))
			if (ko.isObservable(params.allow_collection_selection))
				this.allow_collection_selection = params.allow_collection_selection;
			else
				this.allow_collection_selection = ko.observable(params.allow_collection_selection);
		else
			this.allow_collection_selection = ko.observable(false);
		
		if (params.hasOwnProperty('allow_regular_selection'))
			if (ko.isObservable(params.allow_regular_selection))
				this.allow_regular_selection = params.allow_regular_selection;
			else
				this.allow_regular_selection = ko.observable(params.allow_regular_selection);
		else
			this.allow_collection_selection = ko.observable(false);

		if (params.hasOwnProperty('row_clicked'))
			if (params.row_clicked instanceof Function)
				this.options.row_clicked = params.row_clicked;
		
		if (params.hasOwnProperty('resource_clicked'))
			if (params.resource_clicked instanceof Function)
				this.options.resource_clicked = params.resource_clicked;

		this.update();
	}

	async update()
	{
		if (!this.options.dirname() || !this.options.dirname().length)
			return;

		this.resource(null);
		let resource = new ResourceVM(this.FS, path.join(this.options.dirname(), '/'));
		let props_to_fetch = [
			'{DAV:}resourcetype',
			'{DAV:}getcontenttype',
			'{DAV:}getcontentlength',
			'{DAV:}getlastmodified'
		];

		try {
			await resource.populate(props_to_fetch);
		} catch (err) {
			console.error(err);
			return;
		}

		let children = resource.children();
		children = children.sort((a,b) => { return path.basename(a.href()) > path.basename(b.href()) });
		children = children.sort((a,b) => { return a.collection() === true ? 0 : 1 });
		resource.children(children);

		this.resource(resource);
	}

	btnBreadcrumb_click(entry)
	{
		this.options.dirname(entry.full_path);
	}

	row_click(resource)
	{
		if (resource.collection() === true)
		{
			this.options.dirname(resource.href());
			this.options.basename('');
		}
		else
			this.options.basename(path.basename(resource.href()));
	}
	
	resource_click(resource)
	{
		if (resource.collection() === true)
		{
			// TODO
		}
		else
		{
			// TODO
		}
	}
}

export default {
	name: 'file-selector',
	viewModel: FileSelectorVM,
	module_type: 'ko',
	template: template
};
