
import template from './view-entry.html'
import FileStoreHTTP from '../lib/FileStoreHTTP.mjs';
import ResourceVM from '../lib/ResourceVM.mjs';

class ViewEntryViewModel
{
	constructor(dialog, entry)
	{
		this.dialog = dialog;
		this.entry = entry; //Resource entry
		
		this.FS = new FileStoreHTTP();

		this.history_loaded = false;
		this.history = ko.observableArray([]);

		this.selected_tab = ko.observable('general');

		this.status_code = ko.observable();

		this.status = ko.observable();
		this.status_title = ko.observable();
		this.status_message = ko.observable();

		this.title = ko.observable('Resource: ' + entry.href());
		this.resource = ko.observable(null);
		this.properties = ko.observableArray([]);
		this.acl = ko.observableArray([]);
		this.locks = ko.observableArray([]);

		this.versionedstate = ko.observable({state:'NA'});
		this.version_status = ko.observable('NA');

		this.resourcetype = ko.observable();
		this.resource_id = ko.observable();
		this.contentlength = ko.observable();
		this.contenttype = ko.observable();
		
		this.icon = ko.observable();

		this.childcount = ko.observable('0');
		this.creationdate = ko.observable();
		this.lastmodified = ko.observable();
		this.img_url = ko.observable();
		this.current_user_privileges = ko.observableArray([]);
		this.current_user_privileges_string = ko.computed(() => {
			if (this.current_user_privileges() == null)
				this.current_user_privileges([]);
			return '<span class="label label-success">' + this.current_user_privileges().join('</span> <span class="label label-success">') + '</span>';
		});

		this.editing_displayname = ko.observable(false);
		this.new_displayname = ko.observable();

		this.exclusive_lock = ko.observable(null);
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#view_file_entry_dialog_nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});
		
		event.currentTarget.classList.add('active');
		
		this.selected_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	async fetchInfo ()
	{
		let resource = new ResourceVM(this.FS, this.entry.href);
		let add_props = [ 
			{nsuri: 'DAV:', name: 'acl'},
			{nsuri: 'DAV:', name: 'current-user-privilege-set'},
			{nsuri: 'DAV:', name: 'checked-in'},
			{nsuri: 'DAV:', name: 'checked-out'},
			{nsuri: 'DAV:', name: 'creationdate'},
			{nsuri: 'DAV:', name: 'childcount'},
			{nsuri: 'DAV:', name: 'getcontentlength'},
			{nsuri: 'DAV:', name: 'getcontenttype'},
			{nsuri: 'DAV:', name: 'getlastmodified'},
			{nsuri: 'DAV:', name: 'iscollection'},
			{nsuri: 'DAV:', name: 'lockdiscovery'},
			{nsuri: 'DAV:', name: 'resourcetype'},
			{nsuri: 'DAV:', name: 'resource-id'},
			{nsuri: 'DAV:', name: 'supported-privilege-set'},
			{nsuri: 'PS:', name: 'versionedstate'},
			{nsuri: 'PS:', name: 'icon'}
		];

		try {
			await resource.populate(add_props);

			this.acl(resource.get('DAV:', 'acl'));
			this.resourcetype(resource.get('DAV:', 'resourcetype'));
			this.contenttype(resource.get('DAV:', 'getcontenttype') || 'unknown');
			this.contentlength(resource.get('DAV:', 'getcontentlength'));
			this.creationdate(resource.get('DAV:', 'creationdate'));
			this.childcount(resource.get('DAV:', 'childcount') || '0');
			this.lastmodified(resource.get('DAV:', 'getlastmodified'));
			this.versionedstate(resource.get('PS:', 'versionedstate'));
			this.icon(resource.get('PS:', 'icon'));
			this.current_user_privileges(resource.get('DAV:', 'current-user-privilege-set'));
			let res_id = resource.get('DAV:', 'resource-id');
			if (res_id.startsWith('urn:uuid:'))
				this.resource_id(res_id.substr(9));
			else
				this.resource_id(res_id);

			let props = [];
			for (let property of resource.properties?.properties)
			{
				property.computed = false;
				property.protected = false;
				props.push(property);
			}

			this.properties(props);

			this.locks(resource.locks());
			
			this.status_code('OK');


		} catch (err) {
			console.error(err);

			this.status('danger');
			
			if (err.jqXHR)
			{
				let jqXHR = err.jqXHR;
				if (jqXHR.status == 403)
				{
					this.status_title('Forbidden!');
					this.status_message('You do not have access permission to this resource.');
				}
				else if (jqXHR.status == 404)
				{
					this.status_title('Not found!');
					this.status_message('The resource does not exist.');
				}
				else if (jqXHR.status >= 500)
				{
					this.status_title('Server error!');
					this.status_message('A server error has occured!');
				}
				else if (jqXHR.status >= 400)
				{
					this.status_title('Bad request!');
					this.status_message('The server reported a bad request (status=' + jqXHR.status + ').');
				}
				else if (jqXHR.readyState == 0 && jqXHR.status == 0)
				{
					this.status_title('Connectivity issues!');
					this.status_message('Unable to make requests to the server. Please check your connection.');
				}
				else
				{
					this.status_title('Error (' + jqXHR.status + ' ' + textStatus + ')');
					this.status_message('Error while loading resource information.');
					Grape.alert_xhr_error(jqXHR);
				}
			}
			else
				this.status_message('Error: ' + err.message);
		}

		this.resource(resource);
	} // fetchInfo

	async deleteACE (ace)
	{
		let result = await Grape.alerts.confirm({type: 'warning', title: 'Delete ACE', message: 'Are you sure you want to delete this ACE record for ' + ace.principal.name + '?'});
		if (!result)
			return;

		try {
			await window.Grape.fetches.fetch(`/grape-filestore/ace/${ace.ace_uuid}`, {
				method: 'DELETE',
			});
		} catch (err) {
			console.log(err);
			let message = 'Error while deleting ACE';
			if (err.jqXHR)
				message += ' (status=' + err.jqXHR.textStatus + ')';
			else if (err.message)
				message += ' (' + err.message + ')';
			Grape.alerts.alert({
				type: 'danger',
				title: 'Error',
				message: message
			});
			return;
		}
		this.fetchInfo();
	}

	async fetch_history()
	{
		if (this.resource().collection() === true)
		{
			this.history([]);
			this.version_status(null);
			return;
		}
		
		await this.resource().populate([{nsuri: 'PS:', name: 'versionedstate'}]);
		this.versionedstate(this.resource().get('PS:', 'versionedstate'));

		let versions = await this.resource().report('DAV:', 'version-tree', {get: ['{DAV:}creator-displayname', '{DAV:}getcontentlength', '{DAV:}getlastmodified']});

		this.version_status('CI');
		for (let version of versions)
		{
			version.label = version.labels.join(', ');
			for (let prop of version.properties.values || [])
			{
				if (prop.nsuri == 'DAV:' && prop.name == 'creator-displayname')
					version.creator = prop.value;
				else if (prop.nsuri == 'DAV:' && prop.name == 'getcontentlength')
					version.contentlength = prop.value;
				else if (prop.nsuri == 'DAV:' && prop.name == 'getlastmodified')
					version.lastmodified = prop.value;
			}
		}

		this.history(versions);
	}

	async btnVersionControl_click()
	{
		await Grape.fetches.postJSON('/grape-filestore/version-control', {path: this.entry.href()});
		this.fetchInfo();
	}
	
	async btnCheckout_click()
	{
		await this.resource().checkout();
		await this.fetch_history();
	}
	
	async btnCheckin_click()
	{
		await this.resource().checkin();
		await this.fetch_history();
	}

	async btnDeleteLock_click(lockinfo)
	{
		if (!(lockinfo?.locktoken))
		{
			console.warn('Invalid locktoken value for unlock');
			return;
		}

		let result = await Grape.alerts.confirm({type: 'warning', title: 'Delete lock', message: 'Are you sure you want to delete this lock?'});
		if (!result)
			return;

		await this.resource().unlock(lockinfo.locktoken);
		this.fetchInfo();
	}

	btnClose_click()
	{
		this.dialog.close();
	}


	async editACE_click (ace)
	{
		let result = await Grape.dialog.open('EditACE', {resource: {id: this.resource_id()}, ace: ace});
		if (result)
			this.fetchInfo();
	}

	async addACE_click ()
	{
		let r = await Grape.dialog.open('EditACE', {resource: {id: this.resource_id()}});
		if (r)
			this.fetchInfo();
	}


	async setIcon_click ()
	{
		let result = await Grape.dialog.open('FileSelector', {
			title: 'Choose icon...',
			location: '/DAV/icons/' /* TODO get from settings */
			});
		console.log('dialog result=', result);
		if (result)
		{
			await this.resource().save_property('PS:', 'icon', result);
			this.fetchInfo();
		}
	}

	async acquireExclusiveLock ()
	{
		await this.resource().lock('write', 'exclusive', 'infinite', -1);
		this.fetchInfo();
	}

	async acquireSharedLock ()
	{
		await this.resource().lock('write', 'shared', 'infinite', -1);
		this.fetchInfo();
	}

	async acquireCustomLock ()
	{
		let lockinfo = await Grape.dialog.open('LockInfoInput', {lock: null});
		if (lockinfo)
		{
			await this.resource().lock(lockinfo.type, lockinfo.scope, lockinfo.depth, lockinfo.lifetime);
		}
		this.fetchInfo();
	}

	editDisplayname_click ()
	{
		this.new_displayname(this.displayname());
		this.editing_displayname(true);
	}

	saveDisplayname_click ()
	{
		//this.editing_displayname(false);
	}

	async fetchAllProps_click()
	{
		this.properties([]);
		await this.resource().populate([{nsuri: 'PS:', name: 'real-allprop'}]);
		let props = [];
		for (let prop of this.resource().properties.properties)
			props.push(prop);
		this.properties(props);
	}

	async editResourceTypes_click()
	{
		let result = await Grape.dialog.open('EditResourceTypes', {resource: this.resource()});
		if (result)
			this.fetchInfo();
	}

	async editPropertyValue_click()
	{
		let result = await Grape.dialog.open('EditPropertyValue', {resource: this.resource()});
		if (result)
			this.fetchInfo();
	}

	async populatePropertyValue_click(prop)
	{
		this.properties([]);
		await this.resource().populate([{nsuri: prop.nsuri, name: prop.name}]);
		let props = [];
		for (let prop of this.resource().properties.properties)
			props.push(prop);
		this.properties(props);
	}
}

class ViewFileEntryDialog
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ViewEntryViewModel(this, bindings.entry);
		this.name = 'ViewResourceEntryDialog';
	}

	init ()
	{
		this.viewModel.fetchInfo();
	}
}

export default {
	name: 'ViewResourceEntryDialog',
	dialog_class: ViewFileEntryDialog,
	template: template,
	provider: "ps"
};
